var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "search-attr" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "small", placeholder: "请输入热门币种" },
                model: {
                  value: _vm.searchParams.coinKindTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "coinKindTitle", $$v)
                  },
                  expression: "searchParams.coinKindTitle"
                }
              }),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.openModal({})
                }
              }
            },
            [_vm._v("新增热门币种")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row.tagList, function(tag, key) {
                      return _c(
                        "a-tag",
                        { key: key, attrs: { color: "blue" } },
                        [_vm._v(_vm._s(tag.coinTagName))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "热门币种",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "排序号", prop: "coinKindHotSearchSort" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入排序号" },
                    model: {
                      value: _vm.forms.coinKindHotSearchSort,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindHotSearchSort", $$v)
                      },
                      expression: "forms.coinKindHotSearchSort"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种", prop: "coinKindSid" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.changeSelect, search: _vm.searchData },
                      model: {
                        value: _vm.forms.coinKindSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindSid", $$v)
                        },
                        expression: "forms.coinKindSid"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.sid } },
                        [
                          _vm._v(_vm._s(item.coinKindName)),
                          item.dynastyName
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.dynastyName))
                              ])
                            : _vm._e(),
                          item.dynastyName
                            ? _c("span", [_vm._v(" " + _vm._s(item.rulerName))])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }