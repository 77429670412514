<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="searchParams.coinKindTitle" size='small' style="width:150px;" placeholder='请输入热门币种'></a-input>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>

            <a-button size='small' @click="openModal({})">新增热门币种</a-button>
        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="record=>record.id">
                <div slot="tags" slot-scope="row">
                    <a-tag v-for="(tag,key) in row.tagList" :key="key" color="blue">{{ tag.coinTagName }}</a-tag>
                </div>
                 <div slot="setup" slot-scope="row">
                    <a @click="openModal(row)" style='margin-right:5px'>编辑</a>
                    <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm>
                </div>
                
            </a-table>
        </div>

        <a-modal v-model="visible" title="热门币种" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <a-form-model
                ref="ruleForm"
                :model="forms"
                :rules="rules"
                v-bind="layout">   
                <a-form-model-item label="排序号" prop="coinKindHotSearchSort" >
                    <a-input placeholder='请输入排序号' v-model="forms.coinKindHotSearchSort"></a-input>
                </a-form-model-item> 

                <a-form-model-item label="大币种" prop="coinKindSid" >
                    <a-select @change="changeSelect" @search='searchData' v-model="forms.coinKindSid" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                        <a-select-option v-for="(item,index) of currency_big_list" :key="index" :value='item.sid'>{{item.coinKindName}}<span v-if="item.dynastyName"> {{item.dynastyName}}</span><span v-if="item.dynastyName"> {{item.rulerName}}</span></a-select-option>
                    </a-select>
                </a-form-model-item> 
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
export default {
    data(){
        return {
            columns:[
                {title:'id',dataIndex:'id'},
                {title:'排序号',dataIndex:'coinKindHotSearchSort'},
                {title:'关键词',dataIndex:'coinKindTitle'},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            forms:{
                
            },
            checkUsers:[],
            rules:{
                coinKindHotSearchSort:[{ required: true, message: 'please enter sort', trigger: 'blur' }],
                coinKindSid:[{ type:'string',required: true, message: 'please enter keyword', trigger: 'blur' }],
            },
            visible:false,
            params:{page:1,limit:10,conditions:''},
            IO:0,
            tagCurrent:'',
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            checkAll:false,
            indeterminate:false,
            IO:0,
            searchParams:{
                coinKindTitle:'',
            },
            currency_big_list:[],
        }
    },
    created(){
        this.getData(this.params)
    },
    methods:{
        openModal(row){
            this.visible = true
            const id = row&&row.id ? row.id : 0
            this.IO = id ? 1 : 0
            this.forms = id ? JSON.parse(JSON.stringify(row)) : {}
            if(this.IO == 1){
                this.searchData(row.coinKindTitle)
            }
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        submit(forms){
            const post_url = this.IO ? '/dq_admin/kind_hot_search/edit' : '/dq_admin/kind_hot_search/add'
            this.axios.post(post_url,{...forms}).then(res=>{
                if(res.status == 'SUCCESS'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.getData(this.params)
                }
            })
        },
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/kind_hot_search/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            const strs = this.untils.setParams(this.searchParams)
            this.params.conditions = strs
            this.params.page = 1
            this.getData(this.params)
        },
        async remove(n){
            const res = await this.axios('/dq_admin/kind_hot_search/delKindHotSearchById',{params:{id:n}})
            if(res.status == 'SUCCESS'){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },

        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.page = current
            this.params.limit = pageSize
            this.getData(this.params)
        },
        onChange(checks){
            this.indeterminate = !!checks.length && checks.length < this.users.length
            this.checkAll = checks.length === this.users.length
            this.checkUsers = checks
        },
        onCheckAllChange(e) {
            Object.assign(this, {
                checkUsers: e.target.checked ? this.users.reduce((pre,cur)=>pre.concat([cur.value]),[]) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            });
        },
        changeSelect(e){
            this.forms.coinKindSid = e
            const findData = this.currency_big_list.find((row)=>row.sid == e)
            this.forms.coinKindTitle = findData.coinKindName||''
        },
        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>